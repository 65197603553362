import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MalwareFilterPolicy } from '../../../../../../../interfaces/powershell/exo/malware-filter-policy.interface';
import { BaseComponent } from '../../base/base.component';
import {
    MalwareFileTypesFilterPolicy_Schema,
    generateMalwareFileTypesFilterPolicySchema,
    malwareFileTypesBestPractices,
} from '../../../../../models/baseline-schemas/malware-file-types-filter-policy.schema';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    defaultPolicy: MalwareFilterPolicy;
    @ViewChild('desiredFileTypeValueRef') desiredFileTypeValueRef;

    fileTypesDesiredValuePopup: string[] = [];
    desiredValueTemp: string;
    constructor(
        private dialog: MatDialog,
        private fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit(): void {
        const schema: MalwareFileTypesFilterPolicy_Schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.defaultPolicy = this.data.find((res) => res.IsDefault);

        this.form = this.initForm(schema);

        this.form.valueChanges.subscribe((value) => {
            const schema = generateMalwareFileTypesFilterPolicySchema(value);

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    initForm(schema) {
        return this.fb.group({
            EnableFileFilter: [schema?.contains.properties?.EnableFileFilter?.const],
            FileTypes: [schema?.contains.properties?.FileTypes?.items?.enum || []],
        });
    }

    onFileTypeIgnoreToggle(value: boolean) {
        if (value) {
            this.desiredFileTypeValue = [];
        } else {
            this.updateDesiredFileTypeValueToBestPractices();
        }
    }

    updateDesiredFileTypeValue(): void {
        this.fileTypesDesiredValuePopup = this.desiredFileTypeValue.map((res) => res); // deep copy

        this.dialog.open(this.desiredFileTypeValueRef, {
            width: '700px',
            height: '700px',
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.IsDefault);
    }

    addNewDesiredFileTypeValue(): void {
        const trimmedValue = this.desiredValueTemp?.trim();

        if (trimmedValue && !this.fileTypesDesiredValuePopup.includes(trimmedValue)) {
            this.fileTypesDesiredValuePopup.push(trimmedValue);
        }

        this.desiredValueTemp = '';
    }

    removeNewDesiredFileTypeValue(item: string): void {
        const index = this.fileTypesDesiredValuePopup.indexOf(item);
        if (index > -1) {
            this.fileTypesDesiredValuePopup.splice(index, 1);
        }
    }

    updateDesiredFileTypeValueToBestPractices(): void {
        this.desiredFileTypeValue = malwareFileTypesBestPractices.map((res) => res.toLocaleLowerCase());
    }

    clearAllDesiredFileTypes(): void {
        this.fileTypesDesiredValuePopup = [];
    }

    saveDesiredFileTypeValue(): void {
        this.desiredFileTypeValue = this.fileTypesDesiredValuePopup.map((res) => res.toLocaleLowerCase());
        this.dialog.closeAll();
    }

    get desiredFileType(): FormControl {
        return this.form.get('FileTypes') as FormControl;
    }
    get desiredFileTypeValue(): string[] {
        return this.desiredFileType.value;
    }
    set desiredFileTypeValue(value: string[]) {
        this.desiredFileType.setValue(value);
    }
}
