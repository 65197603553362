<form [formGroup]="form" class="mat-typography" *ngIf="defaultPolicy">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                File Filter
                <mat-icon
                    matTooltip="Enables or disables the common attachments filter (also known as common attachment blocking). ">info</mat-icon>
            </td>

            <td>
                {{ defaultPolicy.EnableFileFilter ? 'Enabled' : 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="EnableFileFilter">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status [hasError]="errorsString.includes('EnableFileFilter')"></sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                File Type
                <mat-icon matTooltip="...">info</mat-icon>
            </td>

            <td>
                <ng-container *ngFor="let type of defaultPolicy.FileTypes">
                    <mat-label>
                        {{ type }} ,
                    </mat-label>
                </ng-container>
            </td>

            <td>
                <div>

                    <mat-slide-toggle class="mt-1" *ngIf="!readOnly"  (change)="onFileTypeIgnoreToggle($event.checked)"  [checked]="desiredFileTypeValue.length === 0">
                        Ignore
                    </mat-slide-toggle>
                </div>

                <ng-container *ngFor="let type of desiredFileTypeValue">
                    <mat-label>
                        {{ type }} ,
                    </mat-label>
                </ng-container>

                <div class="mt-1 flex gap-4">
                    <button *ngIf="!readOnly" mat-button color="primary" (click)="updateDesiredFileTypeValue()" matTooltip="Update">
                        Update value
                    </button>

                    <button *ngIf="!readOnly" mat-button color="primary" (click)="updateDesiredFileTypeValueToBestPractices()" matTooltip="Update">
                        Set values to best practices
                    </button>

                </div>

            </td>

            <td>
                <sway-baseline-status [hasError]="errorsString.includes('FileTypes')"></sway-baseline-status>
            </td>
        </tr>
    </table>
</form>

<ng-template #desiredFileTypeValueRef>
    <h3>File Type</h3>

    <mat-form-field appearance="outline" class="full-width">
        <mat-label>File Type</mat-label>
        <input matInput [(ngModel)]="desiredValueTemp">
    </mat-form-field>

    <div class="button-group top-spacing">
        <button (click)="addNewDesiredFileTypeValue()" mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
        </button>
    </div>

    <div *ngIf="fileTypesDesiredValuePopup.length === 0" class="no-file-type-message">
        Please select a file type to add.
    </div>

    <div class="file-type-list">
        <div *ngFor="let item of fileTypesDesiredValuePopup" class="file-type-item">
            <span>{{ item }}</span>
            <mat-icon (click)="removeNewDesiredFileTypeValue(item)" class="delete-icon">
                delete
            </mat-icon>
        </div>
    </div>

    <div class="button-group">
        <button [disabled]="fileTypesDesiredValuePopup.length === 0" (click)="clearAllDesiredFileTypes()" mat-raised-button color="warn">
            <mat-icon>delete_sweep</mat-icon> Clear All
        </button>
    </div>

    <div class="button-group">
        <button [disabled]="fileTypesDesiredValuePopup.length === 0" (click)="saveDesiredFileTypeValue()" mat-raised-button color="primary">
            Save
        </button>

        <button mat-dialog-close="true" mat-raised-button color="warn">
            Cancel
        </button>
    </div>
</ng-template>
