import { BaselineAudit_Entity } from '../baseline-audit';
import { ArrayTypeSchema, EnumBaselineProp, SchemaValue } from '../schema';
import { Baseline } from '../../../../stores/client/sway/baseline/model';

export const malwareFileTypesBestPractices = [
    'ace',
    'apk',
    'app',
    'appx',
    'ani',
    'arj',
    'bat',
    'cab',
    'cmd',
    'com',
    'deb',
    'dex',
    'dll',
    'docm',
    'elf',
    'exe',
    'hta',
    'img',
    'iso',
    'jar',
    'jnlp',
    'kext',
    'lha',
    'lib',
    'library',
    'lnk',
    'lzh',
    'macho',
    'msc',
    'msi',
    'msix',
    'msp',
    'mst',
    'pif',
    'ppa',
    'ppam',
    'reg',
    'rev',
    'scf',
    'scr',
    'sct',
    'sys',
    'uif',
    'vb',
    'vbe',
    'vbs',
    'vxd',
    'wsc',
    'wsf',
    'wsh',
    'xll',
    'xz',
    'z',
];

export interface MalwareFileTypesFilterPolicy_SchemaData {
    EnableFileFilter: SchemaValue<boolean>;
    FileTypes?: EnumBaselineProp;
    // this filed is required but it is not in the schema for old baselines
    IsDefault: SchemaValue<boolean>;
}

export type MalwareFileTypesFilterPolicy_Schema = ArrayTypeSchema<MalwareFileTypesFilterPolicy_SchemaData>;
export type MalwareFileTypesFilterPolicy_Baseline = Baseline<MalwareFileTypesFilterPolicy_SchemaData>;
export type MalwareFileTypesFilterPolicy_Deviation = BaselineAudit_Entity<MalwareFileTypesFilterPolicy_Schema>;

interface MalwareFileTypesFilterPolicy_Form {
    EnableFileFilter: boolean;
    FileTypes: string[];
    IsDefault: boolean;
}

export function generateMalwareFileTypesFilterPolicySchema(
    formValue: MalwareFileTypesFilterPolicy_Form,
): MalwareFileTypesFilterPolicy_Schema {
    const schema: MalwareFileTypesFilterPolicy_Schema = {
        type: 'array',
        contains: {
            type: 'object',
            properties: {
                IsDefault: { type: 'boolean', const: true },
                EnableFileFilter: { type: 'boolean', const: formValue.EnableFileFilter },
            },
            required: ['EnableFileFilter', 'Identity'],
        },
    };

    if (formValue.FileTypes.length > 0) {
        schema.contains.properties.FileTypes = {
            type: 'array',
            items: {
                type: 'string',
                enum: formValue.FileTypes,
            },
            minItems: formValue.FileTypes.length,
            maxItems: formValue.FileTypes.length,
            uniqueItems: true,
        };

        schema.contains.required.push('FileTypes');
    }

    return schema;
}
