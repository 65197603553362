import { MalwareFilterPolicy } from 'projects/angular-clarity/src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { updateExoMalwareFilterPolicy } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/malware-filter-policy/actions';

export function Remediate(_tenant: string, data: MalwareFilterPolicy[], schema: any) {
    const Identity = data.find((res) => res.IsDefault).Identity;
    return updateExoMalwareFilterPolicy(
        // not finished
        {
            _tenant,
            data: {
                Identity,
                EnableFileFilter: schema.contains.properties.EnableFileFilter.const,
                FileTypes: schema.contains.properties.FileTypes?.items.enum, // undefined if empty
            },
        },
    );
}
