import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AuthenticationMethod,
    DirectoryRole,
    Domain,
    Group,
    MailFolder,
    User,
} from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { catchError, filter, first, forkJoin, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { InboxRule } from '../interfaces/powershell/exo/inbox-rule.interface';
import { Tenant } from '../interfaces/tenant.interface';
import { client } from '../stores/client';
import { fetchGraphAuthenticationMethodsSuccess } from '../stores/client/graph/authentication/methods/actions';
import { fetchGraphPerUserMfaStateSuccess } from '../stores/client/graph/authentication/perUserMfaState/actions';
import { PerUserMfaState } from '../stores/client/graph/authentication/perUserMfaState/model';
import { loadDirectoryRolesSuccess } from '../stores/client/graph/directory-roles/actions';
import {
    directoryRoleMembersLoaded,
    loadDirectoryRoleMembersSuccess,
} from '../stores/client/graph/directory-roles/members/actions';
import { loadDomainsSuccess } from '../stores/client/graph/domains/actions';
import { loadGroupsSuccess } from '../stores/client/graph/group/actions';
import { loadMembersSuccess, membersLoaded } from '../stores/client/graph/group/members/actions';
import { fetchGraphInboxRulesSuccess } from '../stores/client/graph/inbox-rules/actions';
import { loadGraphUsersSuccess } from '../stores/client/graph/user/user.actions';
import { fetchCasMailboxesSuccess } from '../stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from '../stores/client/powershell/exo/cas-mailbox/model';
import { loadMailboxesSuccess } from '../stores/client/powershell/exo/mailbox/actions';
import { Mailbox } from '../stores/client/powershell/exo/mailbox/model';
import { MailboxFolderService } from './mailbox-folder.service';

export function cacheEnabledForTenant(tenant: Tenant): boolean {
    return tenant.cache_enabled && tenant.beta_enabled;
}

function selectCacheEnabled<T extends { _tenant: string }>(store: Store, action: T) {
    return store.select(client(action._tenant).octiga.tenant.data).pipe(
        first((tenant) => !!tenant),
        map(cacheEnabledForTenant),
    );
}

export function skipUntilTenantLoaded<T extends { _tenant: string; type: string }>(store: Store) {
    return (source$: Observable<T>) => {
        return source$.pipe(
            mergeMap((action) =>
                selectCacheEnabled(store, action).pipe(
                    take(1),
                    mergeMap((shouldCancel) => {
                        if (shouldCancel) {
                            console.log('[cancelled action]', action.type!);
                            return of(null); // cancel the action
                        }
                        return of(action); //  continue with the original action
                    }),
                    catchError(() => of(action)), // fallback to original action if condition observable errors
                ),
            ),
            filter((action) => action !== null), // filter out cancelled (null) action
        );
    };
}

// this is a workaround for missing types in typescript < 5.2.x
// this class can be removed once we're on typescript > 5.2.x
declare class DecompressionStream {
    constructor(format: 'gzip' | 'deflate');
    readonly readable: ReadableStream;
    readonly writable: WritableStream;
}

interface Blob {
    users: User[];
    groups: Group[];
    mailboxes: Mailbox[];
    casMailboxes: CasMailbox[];
    directoryRoles: DirectoryRole[];
    domains: Domain[];
    authMethods: {
        userId: string;
        value: AuthenticationMethod[];
    }[];
    mailFolders: {
        userId: string;
        value: MailFolder[];
    }[];
    messageRules: {
        userId: string;
        value: InboxRule[];
    }[];
    perUserMfaState: {
        userId: string;
        value: PerUserMfaState;
    }[];
    // exoMessageRules: {
    //     user_id: string;
    //     rules: EXOInboxRule[];
    // }[];
}

function Base64ToArrayBuffer(base64: string) {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

function DecodeBlob(data: string): Observable<Blob> {
    // TODO: DEV24-580 double-check
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise<Blob>(async (resolve, reject) => {
        const buff_array = Base64ToArrayBuffer(data);
        const compressedStream = new Response(buff_array).body;
        const unZipped = compressedStream.pipeThrough(new DecompressionStream('gzip'));
        const jsonString = await new Response(unZipped).text();
        const payload = JSON.parse(jsonString);
        resolve(payload);
    });

    return new Observable((subscriber) => {
        promise
            .then((value) => {
                subscriber.next(value);
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
            });
    });
}

@Injectable({
    providedIn: 'root',
})
export class BlobService {
    constructor(
        private http: HttpClient,
        private mailFolderService: MailboxFolderService,
    ) {}

    private readonly queryGroups: Array<Array<keyof Blob>> = [
        ['users'],
        ['groups'],
        ['messageRules'],
        // ['exoMessageRules'],
        ['mailFolders'],
        ['mailboxes'],
        ['casMailboxes'],
        ['authMethods'],
        ['domains'],
        ['directoryRoles'],
        ['perUserMfaState'],
    ];

    public load(msp_id: string, tenant_id: string) {
        return forkJoin(this.queryGroups.map((keys) => this.fetchBlob(msp_id, tenant_id, keys))).pipe(
            map((data) => data.reduce((acc, item) => ({ ...acc, ...item }), {})),
            map((data) => this.GenerateActions(tenant_id, data)),
        );
    }

    private fetchBlob(msp_id: string, tenant_id: string, keys: string[]) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'msp-id': msp_id,
            'tenant-id': tenant_id,
        });

        const keysStr = keys.join(',');

        return this.http
            .request('GET', '/api/sync/client/blob', {
                headers,
                responseType: 'text',
                params: {
                    keys: keys.join(','),
                },
            })
            .pipe(
                switchMap((data) => DecodeBlob(data) as Observable<Partial<Blob>>),
                tap((data) => console.log(keysStr, data)),
            );
    }

    private GenerateActions(_tenant: string, data: Partial<Blob>) {
        const {
            authMethods,
            directoryRoles,
            domains,
            groups,
            mailboxes,
            casMailboxes,
            messageRules,
            // exoMessageRules,
            users,
            mailFolders,
            perUserMfaState,
        } = data;

        const actions = [];

        if (users) {
            actions.push(loadGraphUsersSuccess({ _tenant, users }));
        }

        if (groups) {
            if (users) {
                for (const group of groups) {
                    const id = group.id;
                    const members = group.members.map((member) => users.find((user) => user.id === member.id));
                    actions.push(loadMembersSuccess({ _tenant, id, members }));
                }
            }
            actions.push(loadGroupsSuccess({ _tenant, groups }));
            actions.push(membersLoaded({ _tenant }));
        }

        if (directoryRoles) {
            for (const { roleTemplateId, members } of directoryRoles) {
                // note: these must be dispatched before loadDirectoryRolesSuccess to prevent their effect fetching
                const item = { roleTemplateId, members };
                actions.push(loadDirectoryRoleMembersSuccess({ _tenant, item }));
            }
            actions.push(directoryRoleMembersLoaded({ _tenant }));
            actions.push(loadDirectoryRolesSuccess({ _tenant, roles: directoryRoles }));
        }

        if (domains) {
            actions.push(loadDomainsSuccess({ _tenant, domains }));
        }
        if (mailboxes) {
            actions.push(loadMailboxesSuccess({ _tenant, mailboxes }));
        }
        if (casMailboxes) {
            actions.push(fetchCasMailboxesSuccess({ _tenant, data: casMailboxes }));
        }
        if (authMethods) {
            actions.push(fetchGraphAuthenticationMethodsSuccess({ _tenant, data: authMethods }));
        }
        if (messageRules) {
            actions.push(fetchGraphInboxRulesSuccess({ _tenant, rules: messageRules }));
        }
        if (perUserMfaState) {
            actions.push(fetchGraphPerUserMfaStateSuccess({ _tenant, data: perUserMfaState }));
        }

        // if (exoMessageRules) {
        //     for (const { user_id, rules } of exoMessageRules) {
        //         for (const rule of rules) {
        //             actions.push(fetchExoInboxRuleSuccess({ _tenant, user_id, rule }));
        //         }
        //     }
        // }

        if (mailFolders) {
            for (const { userId, value } of mailFolders) {
                for (const folder of value) {
                    this.mailFolderService.put(_tenant, userId, folder.id, folder);
                }
            }
        }

        // for (const action of [...new Set(actions.map(action => action.type))]) {
        //     console.log(action)
        // }

        return actions;
    }
}
